var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AImage',{staticClass:"primary",attrs:{"src":"https://f.hubspotusercontent00.net/hubfs/2480959/PC_Hero_3-1.jpg","height":"100%"}},[_c('m-welcome-label',{attrs:{"auth-type":'signup',"is-icon-show":_vm.isBackIconVisible}}),(_vm.isOtpSent)?_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.$route.query.otpSent)?_c('v-alert',{staticClass:"signup__alert mb-6 login__emailalign",staticStyle:{"font-size":"16px"},attrs:{"type":"success"}},[_vm._v(" PilotCity has sent an One-Time Password via text. Verify by entering code & captcha. ")]):_vm._e(),(_vm.isPhone)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ATextInput',{attrs:{"styling":{
          class: 'captcha-input mt-4 mr-6 ma-0',
          color: 'white',
          outlined: true,
          dark: true,
          rounded: true,
          xLarge: true,
          depressed: true,
          width: '100%',
          placeholder: 'Captcha',
          label: 'Enter Captcha',
          hint: 'Verify you\'re a human...',
          fullWidth: true
        },"single-line":"","dark":""},model:{value:(_vm.captchaInput),callback:function ($$v) {_vm.captchaInput=$$v},expression:"captchaInput"}}),_c('AImage',{attrs:{"src":_vm.captchaImg,"height":"54px"}}),_c('AButton',{attrs:{"styling":{
          class: 'ml-2',
          color: '#ffffff80',
          outlined: false,
          rounded: true,
          depressed: true,
          icon: true,
          small: true
        },"loading":_vm.fetching},on:{"click":_vm.reCaptcha}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1):_vm._e(),_c('v-form',{staticClass:"signup__firstname otp-field mt-5"},[_c('div',[_c('AOtpInput',{model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1),_c('div',{staticClass:"d-flex justify-center flex-column align-center"},[_c('AButton',{attrs:{"styling":{
            color: 'green',
            rounded: true,
            dark: true,
            depressed: true,
            width: '400px',
            xLarge: true
          },"type":"submit","disabled":!_vm.isValidOtp,"loading":_vm.verifying},on:{"click":_vm.verifyOtp}},[_vm._v(" Verify One-Time Password ")]),_c('div',{staticClass:"\n            font-weight-bold\n            h1\n            my-8\n            mx-auto\n            text--white\n            white--text white-text white--text\n            d-flex\n            flex-column\n            align-center\n            justify-center\n          "},[_vm._v(" OR ")]),_c('div',{staticClass:"mx-auto d-flex flex-column align-center justify-center mb-6"},[_c('v-btn',{attrs:{"rounded":"","outlined":"","width":"400px","x-large":"","dark":"","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v("Login without verification")])],1),(_vm.otpMsg)?_c('v-alert',{staticClass:"signup__alert",attrs:{"type":_vm.type}},[_vm._v(" "+_vm._s(_vm.otpMsg)+" ")]):_vm._e(),_c('a',{attrs:{"href":"https://www.pilotcity.com/"}},[_c('img',{staticClass:"nav__logo2 mt-6",attrs:{"src":require("@/assets/Pilotcity_logo.png"),"max-height":"50px"}})])],1)])],1):_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.isEmail)?_c('v-form',{staticClass:"signup__firstname",staticStyle:{"max-width":"500px"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('div',{staticStyle:{"max-width":"350px","margin-left":"75px"}},[(_vm.isEmail)?_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ATextInput',{attrs:{"styling":{
                class: 'signup__input',
                outlined: true,
                rounded: true,
                xLarge: true,
                depressed: true,
                fullWidth: true,
                label: 'Email',
                placeholder: 'Email',
                color: 'white'
              },"error-messages":errors,"dark":"","single-line":"","lowercase":true},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}):_vm._e(),(_vm.isEmail)?_c('validation-provider',{attrs:{"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ATextInput',{attrs:{"styling":{
                class: 'signup__input',
                color: 'white',
                rounded: true,
                fullWidth: true,
                dark: true,
                xLarge: true,
                placeholder: 'Password',
                label: 'Password',
                outlined: true,
                appendIcon: _vm.show1 ? 'mdi-eye' : 'mdi-eye-off'
              },"error-messages":errors,"single-line":"","dark":"","type":_vm.show1 ? 'text' : 'password',"icon-click":_vm.togglePassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}):_vm._e()],1),_c('MConsentTermsPolicy',{attrs:{"terms":_vm.terms},on:{"updateTerms":function($event){_vm.terms = !_vm.terms}}}),_c('AButton',{attrs:{"styling":{
            color: 'green',
            rounded: true,
            dark: true,
            xLarge: true,
            width: '100%',
            depressed: true
          },"disabled":invalid || !_vm.terms,"loading":_vm.loading},on:{"click":_vm.signUp}},[_vm._v(" Signup ")]),(_vm.msg)?_c('v-alert',{staticClass:"signup__alert",attrs:{"type":_vm.type}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e(),_c('div',{staticClass:"signup__newaccount text-center"},[(_vm.programNameData || _vm.isDialog)?_c('div',{on:{"click":function($event){return _vm.showLoginModal()}}},[_c('a',{staticClass:"signup__signuplink white--text text-center text-decoration-underline"},[_vm._v(" Have an account already? Login.")])]):_c('div',[_c('router-link',{attrs:{"to":{
                name: 'login',
                params: { page: _vm.$route.params.page },
                query: { redirect: _vm.redirectPath }
              }}},[_c('a',{staticClass:"signup__signuplink white--text text-center"},[_vm._v(" Have an account already? Login.")])])],1)])]}}],null,false,2486926540)})],1):_c('v-form',{staticClass:"signup__firstname",staticStyle:{"max-width":"500px"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return [_c('div',{staticStyle:{"max-width":"350px","margin-left":"75px"}},[_c('validation-provider',{attrs:{"slim":"","rules":"required"}},[_c('ATeleInput',{staticClass:"mb-4",attrs:{"is-rounded":true},on:{"get":_vm.getValidation},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})],1)],1),_c('MConsentTermsPolicy',{attrs:{"terms":_vm.terms},on:{"updateTerms":function($event){_vm.terms = !_vm.terms}}}),_c('AButton',{attrs:{"styling":{
            color: 'green',
            rounded: true,
            dark: true,
            xLarge: true,
            width: '100%',
            depressed: true
          },"type":"submit","disabled":invalid || !_vm.terms || !_vm.isPhoneValid || !_vm.isPhoneCheck,"loading":_vm.loading},on:{"click":_vm.signUp}},[_vm._v(" Continue ")]),(_vm.msg)?_c('v-alert',{staticClass:"signup__alert",attrs:{"type":_vm.type}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]):_vm._e(),_c('div',{staticClass:"signup__newaccount text-center"},[(_vm.programNameData || _vm.isDialog)?_c('div',{on:{"click":function($event){return _vm.showLoginModal()}}},[_c('a',{staticClass:"signup__signuplink white--text text-center text-decoration-underline"},[_vm._v(" Have an account already? Login.")])]):_c('div',[_c('router-link',{attrs:{"to":{
                name: 'login',
                params: { page: _vm.$route.params.page },
                query: { redirect: _vm.redirectPath }
              }}},[_c('a',{staticClass:"signup__signuplink white--text text-center"},[_vm._v(" Have an account already? Login.")])])],1)])]}}])})],1),_c('div',{staticClass:"d-flex justify-center mt-6 flex-column align-center",staticStyle:{"margin-bottom":"60px"}},[_c('a',{attrs:{"href":"https://www.pilotcity.com/"}},[_c('img',{staticClass:"nav__logo2",attrs:{"src":require("@/assets/Pilotcity_logo.png"),"max-height":"50px"}})]),_c('div',{staticClass:"or mt-3"},[_vm._v("OR")]),(_vm.isEmail)?_c('AButton',{attrs:{"styling":{
          class: 'mt-6',
          width: '100%',
          rounded: true,
          dark: true,
          depressed: true,
          color: 'green',
          xLarge: true
        }},on:{"click":function($event){_vm.isEmail = false;
          _vm.isPhone = true;
          _vm.terms = false;}}},[_vm._v(" Continue with phone ")]):_c('AButton',{attrs:{"styling":{
          width: '100%',
          class: 'mt-6',
          rounded: true,
          dark: true,
          depressed: true,
          color: 'green',
          xLarge: true
        }},on:{"click":function($event){_vm.isEmail = true;
          _vm.isPhone = false;
          _vm.terms = false;}}},[_vm._v(" Signup with Email ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }