



























































































































































































































































































































import { reactive, toRefs, ref, Ref, computed } from '@vue/composition-api';
import { useUserActions } from '@/store';
import axios from 'axios';
import { onLogin } from '@/vue-apollo';
import AOtpInput from '@/components/atoms/AOtpInput.vue';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import { addStakeholderToOrg } from '@/api/organizationApi';
import ATextInput from '@/components/atoms/ATextInput.vue';
import AImage from '@/components/atoms/AImage.vue';
import AButton from '@/components/atoms/AButton.vue';
import MWelcomeLabel from '@/components/molecules/m-welcome-label.vue';

interface IUi {
  msg: string;
  type: string;
  loading: boolean;
}

export default {
  name: 'Signup',
  components: {
    AOtpInput,
    ATeleInput,
    ATextInput,
    AImage,
    AButton,
    MWelcomeLabel,
    // AWarning: () => import('@/components/atoms/AWarning.vue'),
    MConsentTermsPolicy: () => import('@/components/molecules/MConsentTermsPolicy.vue')
  },
  props: {
    program: {
      required: false,
      type: Boolean,
      default: false
    },
    isSponsor: {
      type: Boolean,
      default: false
    },
    isDialog: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, context: any) {
    const { loginUser } = useUserActions(['loginUser']);
    const otpMsg = ref('');
    const dialog: Ref<boolean> = ref(false);
    const show1: Ref<boolean> = ref(false);
    const isOtpSent: any = ref(false);
    const isEmail: any = ref(false);
    const isPhone: any = ref(true);
    const verifying = ref(false);
    const signingIn = ref(false);
    const isPhoneValid = ref(false);
    const otp = ref('');
    const token = ref('');
    const captchaInput = ref('');
    const captchaImg: any = ref('');
    // const warningMessage = ref(
    //   "⚠️ SMS campaign registry is still under construction. We apologize for the inconvenience. You can't currently login / signup with SMS. Please use email instead."
    // );
    const stateEmail = reactive({
      email: '',
      password: '',
      type: 'email_signup',
      terms: false,
      error: ''
    });
    const statePhone: any = reactive({
      type: 'phone_number_signup',
      phone_number: '',
      formatted_number: '',
      terms: false
    });
    const {
      root: { $route, $router }
    }: any = context;
    const redirectPath = $route.query?.redirect;

    if (context.root.$route.query.otpSent) {
      isOtpSent.value = context.root.$route?.query?.otpSent;
      captchaImg.value = context.root.$route?.query?.captchaImg;
      statePhone.formatted_number = context.root.$route?.query?.phone;
    }

    if (props.isSponsor) {
      isEmail.value = true;
      isPhone.value = false;
    }

    const param: string = (context.root.$route.query.email as string) || '';
    stateEmail.email = param;

    const ui: IUi = reactive({
      msg: '',
      type: 'success',
      loading: false
    });

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted) {
        statePhone.formatted_number = `+${val.countryCallingCode}${val.formatted}`;
        isPhoneValid.value = val.valid;
      } else {
        statePhone.formatted_number = '';
      }
    }

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (
        statePhone.phone_number.includes(substring) ||
        statePhone.phone_number.includes(substring1)
      ) {
        return false;
      }

      return true;
    });

    const isValidOtp = computed(() => {
      if (otp.value.length === 6 && captchaInput.value.length === 4 && isPhone.value) {
        return true;
      }
      if (otp.value.length === 6 && isEmail.value) {
        return true;
      }
      return false;
    });

    const login = async (val): Promise<void> => {
      try {
        const user = await loginUser(val);
        signingIn.value = false;
        try {
          await onLogin(token.value);
          if (JSON.parse(localStorage.getItem('SelectedOrg'))) {
            const data = {
              user_id: user?._id.toString(),
              organization_ids: [JSON.parse(localStorage.getItem('SelectedOrg'))?._id],
              role: 'admin'
            };
            await addStakeholderToOrg(data);
          }
        } catch (err) {
          signingIn.value = false;
        }
        if (localStorage.getItem('program')) {
          $router.push({ name: 'setup' });
        } else if ($route.params.page) {
          $router.push({ path: $route.params.page });
        } else if (!localStorage.getItem('setRoute') && !localStorage.getItem('SelectedOrg')) {
          $router.push({ name: 'setup' });
        }
      } catch (err) {
        console.log(err);
        signingIn.value = false;
        verifying.value = false;
      }
    };

    async function verifyToken(val) {
      token.value = val;
      try {
        const data = {
          token: val
        };

        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/verify-jwt`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp) {
          login(resp.data.data);
        }
      } catch (error) {
        console.log(error);
        signingIn.value = false;
        verifying.value = false;
      }
    }

    async function verifyOtp() {
      try {
        verifying.value = true;
        const phnState = {
          type: 'phone_number_verify',
          phone_number: statePhone.formatted_number,
          captcha_code: captchaInput.value,
          code: otp.value
        };

        const emailState = {
          type: 'email_otp_verify',
          email: stateEmail.email,
          code: otp.value
        };

        const data = isEmail.value ? emailState : phnState;
        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/verify-otp`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.status === 200) {
          ui.type = 'success';
          otpMsg.value = resp.data.message;
          verifyToken(resp.data.data.access_token);
        }
      } catch (error) {
        ui.type = 'error';
        otpMsg.value = error?.response?.data?.error?.description;
        verifying.value = false;
      }
    }

    async function signIn() {
      signingIn.value = true;
      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;

        const data = {
          email: stateEmail.email,
          type: 'email_otp_signin'
        };

        const resp = await axios.post(`${API_ENDPOINT}/sign-in`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        ui.type = 'success';
        ui.msg = resp.data.message;
        ui.loading = false;
        isOtpSent.value = true;
        signingIn.value = false;
      } catch (error: any) {
        ui.msg = error?.response?.data?.error?.description;
        ui.type = 'error';
        signingIn.value = false;
      }
    }

    const signUp = async (): Promise<void> => {
      ui.loading = true;

      const value = window.location.href.split('/');
      const urlVal = `${value[4]}`;
      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;

        const emailPayload = {
          ...stateEmail,
          email: stateEmail.email.toLowerCase()
        };

        const phnPayload = {
          ...statePhone,
          phone_number: statePhone.formatted_number
        };

        const data = isEmail.value ? emailPayload : phnPayload;
        const resp = await axios.post(`${API_ENDPOINT}/sign-up`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.status === 200 && isPhone.value) {
          ui.type = 'success';
          ui.msg = resp.data.message;
          captchaImg.value = resp.data.data.captcha;
          isOtpSent.value = true;
        } else {
          ui.type = 'success';
          await signIn();
        }
      } catch (error) {
        console.log(error);
        ui.type = 'error';
        ui.msg = error?.response?.data?.error?.description;
        ui.loading = false;
      }
    };

    const fetching = ref(false);
    async function reCaptcha() {
      try {
        fetching.value = true;
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;
        const data = {
          phone_number: statePhone.formatted_number
        };

        const resp = await axios.post(`${API_ENDPOINT}/resend-captcha`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.data) {
          captchaImg.value = resp.data.captcha;
          fetching.value = false;
        }
      } catch (error) {
        fetching.value = false;
        console.log(error);
      }
    }

    function togglePassword() {
      show1.value = !show1.value;
    }

    function displayEmailForm() {
      isEmail.value = true;
      isPhone.value = false;
    }

    function displayMobileForm() {
      isEmail.value = false;
      isPhone.value = true;
    }

    let programNameVal = props.program;

    const programNameData = computed({
      get: () => programNameVal,
      set: newVal => {
        programNameVal = newVal;
      }
    });
    const isBackIconVisible = ref(true);
    if (programNameData.value === true) {
      isBackIconVisible.value = false;
    }

    function showLoginModal() {
      context.emit('signInModal');
    }

    return {
      ...toRefs(stateEmail),
      ...toRefs(statePhone),
      ...toRefs(ui),
      signUp,
      reCaptcha,
      isEmail,
      dialog,
      show1,
      redirectPath,
      fetching,
      isOtpSent,
      isPhone,
      otp,
      verifyOtp,
      verifying,
      otpMsg,
      isValidOtp,
      isPhoneValid,
      getValidation,
      isPhoneCheck,
      captchaImg,
      captchaInput,
      togglePassword,
      displayEmailForm,
      displayMobileForm,
      programNameData,
      showLoginModal,
      isBackIconVisible
      // warningMessage
    };
  }
};
